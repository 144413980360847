<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>
          Nieuw wachtwoord kiezen
        </h1>
      </v-col>

      <v-col cols="12" class="mb-4">
        Er is een email verstuurd met een 6-cijferige bevestigingscode. Vul de code hieronder in en kies een wachtwoord.
      </v-col>

      <v-col cols="12" class="bm-4">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="email"
              label="Email"
              required
          ></v-text-field>

          <v-text-field
              v-model="verificationCode"
              label="6-cijferige bevestigingscode"
              required
          ></v-text-field>

          <v-text-field
              v-model="password"
              label="Wachtwoord"
              type="password"
              required
          ></v-text-field>

          <v-text-field
              v-model="passwordRepeat"
              label="Wachtwoord bevestigen"
              type="password"
              required
          ></v-text-field>

          <v-btn color="accent" class="mr-4" block @click="submit" :loading="loading" :disabled="loading">Naar mijn account</v-btn>
        </form>
      </v-col>
    </v-row>

    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show"/>

    <snackbar-text text="Account wachtwoord instellen gelukt, je wordt doorstuurd."
                   bg-color="green"
                   text-color="white"
                   v-model="showSuccess"
                   :button="false"
    />
  </v-container>
</template>

<script>

import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";
import {mapActions} from "vuex";

export default {
  name: "Register",
  components: {SnackbarText},
  data: () => ({
    email: null,
    verificationCode: null,
    password: null,
    passwordRepeat: null,
    error: {
      show: false,
      message: ""
    },
    showSuccess: false,
    loading: false,
  }),
  created() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt'
    }),
    submit() {
      this.loading = true;

      let params = {
        email: this.email,
        forgot_token: this.verificationCode,
        password: this.password,
        password_confirmation: this.passwordRepeat
      }

      apiClient.post("auth/forgot", params).then(response => {
        this.showSuccess = true;

        this.attempt(response.data).then(() => {
          setTimeout(() => {
            this.$router.push({name: 'dashboardHome'})
          }, 1500);
        }).catch((err) => {
          setTimeout(() => {
            this.$router.push({name: 'login'})
          }, 1500);
        });

      }).catch(error => {
        if (error.data && error.data.message) {
          if (error.data.message === "'email' is required") {
            this.error.message = "Email veld is verplicht";
          } else if (error.data.message === "'email' with that id does not exists") {
            this.error.message = "Email is niet geldig of onbekend.";
          } else if (error.data.message === "'register_token' is required") {
            this.error.message = "Voer een bevestigingscode in. Deze is per email verstuurd.";
          } else if (error.data.message === "'register_token' with that id does not exists") {
            this.error.message = "Bevestigingscode niet geldig.";
          } else if (error.data.message === "'password' is required") {
            this.error.message = "Vul een wachtwoord in.";
          } else if (error.data.message === "'password' is not valid") {
            this.error.message = "Wachtwoord of wachtwoord bevestigen is ongeldig.";
          } else {
            this.error.message = "Er is een onbekende fout opgetreden.";
          }
        } else if (error.status === 302) {
          this.error.message = "Je wachtwoord is aangepast! Maar we kunnen je niet inloggen, je hebt geen toegang tot de Humanwise app.";
        } else {
          this.error.message = "Er is een onbekende fout opgetreden! Als dit probleem zich blijft voordoen neem dan contact met ons op";
        }

        this.error.show = true;
        this.loading = false;
      });
    }
  },
};
</script>
